<template>
  <b-card no-body class="h-100">
    <b-card-header>
      <b-card-title> Data Cleansing </b-card-title>
    </b-card-header>
    <b-card-body>
      <b-card-text class="mb-2">
        Halaman untuk mengelola berita. Silakan gunakan format excel yang
        tersedia untuk melakukan cleansing data. Mohon tidak mengedit kolom yang
        tidak diizinkan untuk diedit.
      </b-card-text>
      <div class="d-flex">
        <!-- FILE FORM -->
        <b-form-file
          v-model="file"
          placeholder="*Pilih File atau Drop Disini ..."
          drop-placeholder="Drop File Disini ..."
          class="mr-1"
          style="width: 30%"
        />

        <!-- UPLOAD BUTTON -->
        <b-button class="mr-1" variant="primary" size="sm">
          Upload File
        </b-button>
      </div>

      <div class="d-flex mt-2">
        <!-- DOWNLOAD TEMPLATE BUTTON -->
        <b-button
          variant="outline-primary"
          class="mr-1 ml-auto"
          v-b-modal.download-template
        >
          <feather-icon icon="DownloadIcon" class="mr-50" />
          Download Template
        </b-button>

        <!-- SEARCH FORM -->
        <b-input-group class="input-group-merge" style="width: 30%">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" class="text-primary" />
          </b-input-group-prepend>
          <b-form-input placeholder="Search" v-model="table_data.filter" />
        </b-input-group>
      </div>
    </b-card-body>

    <!-- TABLE -->
    <dataTable :data="table_data" />

    <!-- DOWNLOAD TEMPLATE FORM MODAL -->
    <downloadTemplateModal :data="modal_data" />
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText,
  BFormFile,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";
import dataTable from "@/components/usable/dataTable.vue";
import downloadTemplateModal from "@/components/data-cleansing/downloadTemplateModal.vue";

export default {
  name: "DataCleansing",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
    BFormFile,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    dataTable,
    downloadTemplateModal,
  },

  data() {
    return {
      title: "Data Cleansing | Online Media Monitoring",
      file: null,
      modal_data: {
        title: "Export Data Cleansing",
        id: "download-template",
      },
      table_data: {
        row: 5,
        filter: null,
        fields: [
          {
            key: "konten",
            label: "konten",
            thClass: "text-center ",
            tdClass: "text-center",
            sortable: true,
            thStyle: { paddingRight: "2rem", paddingTop: "1.3rem" },
          },
          {
            key: "judul",
            label: "judul",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: false,
            thStyle: { paddingTop: "1.3rem" },
          },
          {
            key: "id_berita",
            label: "id berita",
            thClass: "text-center",
            tdClass: "text-center ",
            sortable: true,
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "tanggal",
            label: "tanggal",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: false,
            thStyle: { paddingTop: "1.3rem" },
          },
          {
            key: "topik",
            label: "topik",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: false,
            thStyle: { paddingTop: "1.3rem" },
          },
          {
            key: "akun",
            label: "akun",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: true,
            thStyle: { paddingRight: "2rem", paddingTop: "1.3rem" },
          },
          {
            key: "jurnalis",
            label: "jurnalis",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: true,
            thStyle: { paddingRight: "2rem", paddingTop: "1.3rem" },
          },
          {
            key: "sumber",
            label: "sumber",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: true,
            thStyle: { paddingRight: "2rem", paddingTop: "1.3rem" },
          },
          {
            key: "url",
            label: "url",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: true,
            thStyle: { paddingRight: "2rem", paddingTop: "1.3rem" },
          },
          {
            key: "sentimen",
            label: "sentimen",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: true,
            thStyle: { paddingRight: "2rem", paddingTop: "1.3rem" },
          },
          {
            key: "emotion",
            label: "emotion",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: true,
            thStyle: { paddingRight: "2rem", paddingTop: "1.3rem" },
          },
          {
            key: "ad_value",
            label: "ad value",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: true,
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "pr_value",
            label: "pr value",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: true,
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "halaman",
            label: "halaman",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: true,
            thStyle: { paddingRight: "2rem", paddingTop: "1.3rem" },
          },
          {
            key: "jenis_akun",
            label: "jenis akun",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: true,
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "newstrend",
            label: "newstrend",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: true,
            thStyle: { paddingRight: "2rem", paddingTop: "1.3rem" },
          },
          {
            key: "hapus_topik",
            label: "hapus topik",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: true,
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "status_validasi",
            label: "status validasi",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: true,
            thStyle: { paddingRight: "2rem" },
          },
        ],
        items: [
          {
            konten: "konten",
            judul: "judul",
            id_berita: "id_berita",
            tanggal: "tanggal",
            topik: "topik",
            akun: "akun",
            jurnalis: "jurnalis",
            sumber: "sumber",
            url: "url",
            sentimen: "sentimen",
            emotion: "emotion",
            ad_value: "ad value",
            pr_value: "pr value",
            halaman: "halaman",
            jenis_akun: "jenis akun",
            newstrend: "newstrend",
            hapus_topik: "hapus topik",
            status_validasi: "status validasi",
          },
          {
            konten: "konten",
            judul: "judul",
            id_berita: "id_berita",
            tanggal: "tanggal",
            topik: "topik",
            akun: "akun",
            jurnalis: "jurnalis",
            sumber: "sumber",
            url: "url",
            sentimen: "sentimen",
            emotion: "emotion",
            ad_value: "ad value",
            pr_value: "pr value",
            halaman: "halaman",
            jenis_akun: "jenis akun",
            newstrend: "newstrend",
            hapus_topik: "hapus topik",
            status_validasi: "status validasi",
          },
          {
            konten: "konten",
            judul: "judul",
            id_berita: "id_berita",
            tanggal: "tanggal",
            topik: "topik",
            akun: "akun",
            jurnalis: "jurnalis",
            sumber: "sumber",
            url: "url",
            sentimen: "sentimen",
            emotion: "emotion",
            ad_value: "ad value",
            pr_value: "pr value",
            halaman: "halaman",
            jenis_akun: "jenis akun",
            newstrend: "newstrend",
            hapus_topik: "hapus topik",
            status_validasi: "status validasi",
          },
          {
            konten: "konten",
            judul: "judul",
            id_berita: "id_berita",
            tanggal: "tanggal",
            topik: "topik",
            akun: "akun",
            jurnalis: "jurnalis",
            sumber: "sumber",
            url: "url",
            sentimen: "sentimen",
            emotion: "emotion",
            ad_value: "ad value",
            pr_value: "pr value",
            halaman: "halaman",
            jenis_akun: "jenis akun",
            newstrend: "newstrend",
            hapus_topik: "hapus topik",
            status_validasi: "status validasi",
          },
          {
            konten: "konten",
            judul: "judul",
            id_berita: "id_berita",
            tanggal: "tanggal",
            topik: "topik",
            akun: "akun",
            jurnalis: "jurnalis",
            sumber: "sumber",
            url: "url",
            sentimen: "sentimen",
            emotion: "emotion",
            ad_value: "ad value",
            pr_value: "pr value",
            halaman: "halaman",
            jenis_akun: "jenis akun",
            newstrend: "newstrend",
            hapus_topik: "hapus topik",
            status_validasi: "status validasi",
          },
        ],
      },
    };
  },

  created() {
    document.title = this.title;
  },
};
</script>
  