<template>
  <b-modal
    :id="data.id"
    :title="data.title"
    ok-title="Simpan"
    @ok.prevent="validationForm()"
    cancel-title="Batal"
    title-tag="h4"
    cancel-variant="outline-danger"
    ok-variant="info"
    no-close-on-backdrop
  >
    <!-- CUSTOM MODAL TITLE -->
    <template #modal-title>
      <h4 class="row" style="margin-left: 1px">
        <b>{{ data.title }}</b>
      </h4>
      <small class="row" style="margin-left: 1px">
        Silahkan lengkapi form untuk export Data Cleansing
      </small>
    </template>

    <validation-observer ref="sendEmailForm">
      <b-form class="mx-1">
        <!-- DATE SHORTCUT -->
        <div class="d-flex my-1 shortcut">
          <!-- TODAY -->
          <b-badge
            pill
            variant="light-primary"
            class="mr-1 clickable shortcut"
            @click="setToday()"
            style="margin-left:-15px"
            >Hari Ini</b-badge
          >

          <!-- YESTERDAY -->
          <b-badge
            pill
            variant="light-primary"
            class="mr-1 clickable shortcut"
            @click="setYesterday()"
            >Kemarin</b-badge
          >

          <!-- LAST 7 DAY -->
          <b-badge
            pill
            variant="light-primary"
            class="clickable shortcut"
            @click="setOneWeekAgo()"
            >7 Hari Terakhir</b-badge
          >
        </div>
        <!-- START DATE -->
        <validation-provider
          #default="{ errors }"
          name="Tanggal Awal"
          rules="required"
          class="row mb-2"
        >
          <date-picker
            v-model="start_date"
            type="date"
            popup-class="rounded mt-1"
            input-class="form-control w-100 clickable"
            placeholder="Pilih Tanggal Awal"
            format="DD MMM YYYY"
            class="w-100"
          >
            <!-- CUSTOM CALENDAR ICON -->
            <template #icon-calendar>
              <div>
                <i
                  class="bi bi-calendar icon"
                  style="opacity: 0.5; margin-right: 6px"
                ></i>
              </div>
            </template>
          </date-picker>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>

        <!-- END DATE -->
        <validation-provider
          #default="{ errors }"
          name="Tanggal Akhir"
          rules="required"
          class="row mb-2"
        >
          <date-picker
            v-model="end_date"
            type="date"
            popup-class="rounded mt-1"
            input-class="form-control w-100 clickable"
            placeholder="Pilih Tanggal Akhir"
            format="DD MMM YYYY"
            class="w-100"
          >
            <!-- CUSTOM CALENDAR ICON -->
            <template #icon-calendar>
              <div>
                <i
                  class="bi bi-calendar icon"
                  style="opacity: 0.5; margin-right: 6px"
                ></i>
              </div>
            </template>
          </date-picker>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>

        <!-- JENIS DATA -->
        <validation-provider
          #default="{ errors }"
          name="Jenis Data"
          rules="required"
          class="row"
        >
          <v-select
            v-model="jenis_data"
            placeholder="Pilih Jenis Data"
            :options="option"
            class="w-100"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import {
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BBadge,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

export default {
  name: "downloadTemplateModal",
  components: {
    BModal,
    BForm,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    BBadge,
    vSelect,
    VBModal,
    ValidationProvider,
    ValidationObserver,
    DatePicker,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      jenis_data: "",
      start_date: "",
      end_date: "",
      option: ["Media Sosial", "Media Konvensional"],
      required,
    };
  },

  methods: {
    validationForm() {
      this.$refs.sendEmailForm.validate().then((success) => {
        if (success) {
          this.$bvModal.hide(this.data.id);
        }
      });
    },
    setToday() {
      this.start_date = new Date();
      this.end_date = new Date();
    },
    setYesterday() {
      this.start_date = new Date();
      this.end_date = new Date();
      this.start_date.setDate(this.start_date.getDate() - 1);
      this.end_date.setDate(this.end_date.getDate() - 1);
    },
    setOneWeekAgo() {
      this.start_date = new Date();
      this.end_date = new Date();
      this.start_date.setDate(this.start_date.getDate() - 7);
    },
  },

  directives: {
    "b-modal": VBModal,
  },
};
</script>
<style scoped>
.shortcut :hover {
  opacity: 0.8 !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
